<template>
  <List
    title="locations"
    resource="locations"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    on-row-click="edit"
    :search="true"
    search-placeholder="Search for a location by name"
    :initial-filter="{ deletedEntity: 'all' }"
    :filter-mapper="mapFilterToQuery"
    data-provider="$raaDataProvider"
    :pageSize="20"
    infinity-scroll
  >
    <template v-slot:filters="filterScope">
      <div class="form-row">
        <SelectInput
          name="deletedEntity"
          :label="entityFilterName"
          :options="entityFilterOptions"
          no-clear
        />
        <SelectInput
          name="customerId"
          label="customer"
          :options="customers"
        />
        <SelectInput
          :disabled="disableCommunitiesFilter"
          name="communityId"
          label="community"
          :options="communities"
        />
      </div>
      <div class="form-row justify-end">
        <button @click.stop="filterScope.reset()" class="btn-primary btn-transparent mr-8">Reset</button>
        <button class="btn-primary">Filter</button>
      </div>
    </template>
    <template v-slot:inline-actions="actionsScope">
      <button
        class="btn-action-warning"
        v-if="!actionsScope.record.deletedAt"
        @click.stop="deleteLocation(actionsScope.record)"
      >
        <icon class="w-5 h-5 mr-1" name="trash"/>
      </button>
    </template>
  </List>
</template>

<script>
  import List from "@/components/auth/list/List";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import SelectInput from "@/components/form/SelectInput";
  import Icon from "@/components/ui/Icon";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import EntityFilterMixin from "@/components/auth/EntityFilterMixin";

  export default {
    name: "LocationsList",
    components: {
      SelectInput,
      List,
      Icon
    },
    mixins: [ModalNavigation, ConfirmationMixin, EntityFilterMixin, NotifyMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
    data: function () {
      return {
        fields: [
          {
            name: 'name',
            sortField: 'name',
            title: 'name',
          },
          {
            name: 'customerName',
            sortField: 'customerName',
            title: 'customer',
          },
          {
            name: 'communityName',
            sortField: 'communityName',
            title: 'community',
            titleClass: 'whitespace-no-wrap',
          },
          {
            name: 'source',
            sortField: 'source',
            title: 'source',
            titleClass: 'whitespace-no-wrap',
          },
          {
            name: 'inline-actions',
            title: '',
            width: '10%',
          }
        ],
        customers: [],
        communities: [],
        disableCommunitiesFilter: true
      }
    },
    mounted() {
      this.$authDataProvider.getList('customers', {size: 999})
        .then(({content}) => {
          this.customers = content.filter(({deletedAt}) => !deletedAt)
            .map(({customerId: key, name: value}) => ({key, value}));
        })
        .catch((err) => this.notifyError(err.message));

      this.$watch(
        () => this.$refs.list.$refs.filter.$refs.filterForm.formState.values.customerId,
        customerId => {
          if (customerId) {
            this.$authDataProvider.getList('customerCommunities', {size: 999, customerId})
              .then(({content}) => this.communities = content.filter(({deletedAt}) => !deletedAt).map(({id: key, name: value}) => ({
                key,
                value
              })))
              .catch(error => this.notifyError(error.message));
          }

          this.disableCommunitiesFilter = !customerId;
        }
      )
    },
    methods: {
      deleteLocation(location) {
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this location?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('locations', {id: location.id})
              .then(() => {
                this.$refs.list.doSearch();
                this.notifySuccess('location deleted');
              })
              .catch(error => this.notifyError(error.message))
            : null
          );
      },
      mapFilterToQuery({ deletedEntity, ...rest } = {}) {
        const deletedFilter = this.mapEntityFilterToQuery(deletedEntity);
        return {
          ...rest,
          ...deletedFilter,
        };
      },
    }
  }
</script>

<style scoped>

</style>
